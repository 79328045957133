.slick-track{
  overflow:hidden;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

@media all and (max-width: 800px) { .slick-list { max-width: 100vw; padding: 20px !important; } }
.feature-slider .slick-arrow{
  font-size: 48px;
  color:black;
}
.feature-slider .slick-prev{
  left:25px;
  z-index: 10000;
}
.feature-slider .slick-next{
  right:25px;
  z-index: 10000;
}
.feature-slider .slick-next:before, .feature-slider .slick-prev:before{
  content:'';
}
.feature-slider .slick-arrow i:hover{
  color:blue;
}
